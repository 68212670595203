<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar :background-color="sidebarBackground" short-title="Argon" title="Argon">
      <template slot="links">
        <sidebar-item
          :link="{name: 'Dashboard',icon: 'ni ni-tv-2 text-primary',path: '/dashboard'}"
        />
        <!-- <sidebar-item :link="{name: 'Icons', icon: 'ni ni-planet text-blue', path: '/icons'}"/>
        <sidebar-item :link="{name: 'Maps', icon: 'ni ni-pin-3 text-orange', path: '/maps'}"/>
        <sidebar-item :link="{name: 'User Profile', icon: 'ni ni-single-02 text-yellow', path: '/profile'}"/>
        <sidebar-item :link="{name: 'Register', icon: 'ni ni-circle-08 text-pink', path: '/register'}"/>
        <sidebar-item :link="{name: 'Tables', icon: 'ni ni-bullet-list-67 text-red', path: '/tables'}"/>-->
        <sidebar-item
          :link="{name: 'Services', icon: 'ni ni-bullet-list-67 text-blue', path: '/services'}"
        />
        <sidebar-item
          :link="{name: 'Api Endpoints', icon: 'ni ni-bullet-list-67 text-blue', path: '/api-endpoints'}"
        />
        <sidebar-item
          :link="{name: 'Frontend Routes', icon: 'ni ni-bullet-list-67 text-blue', path: '/frontend-routes'}"
        />
        <sidebar-item
          :link="{name: 'Roles', icon: 'ni ni-bullet-list-67 text-blue', path: '/roles'}"
        />
        <sidebar-item
          :link="{name: 'Users', icon: 'ni ni-bullet-list-67 text-blue', path: '/users'}"
        />
         <sidebar-item
          :link="{name: 'Admin Users', icon: 'ni ni-bullet-list-67 text-blue', path: '/admin-users'}"
        />
        <sidebar-item
        v-if="isLoggedin"
        :link="{name: 'Logout', icon: 'ni ni-key-25 text-info', path: '/login'}"  @click.native="logout"
        />
        <!-- <sidebar-item v-if="!isLoggedin" :link="{name: 'Login', icon: 'ni ni-key-25 text-info', path: '/login'}"/> -->
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <!-- <content-footer v-if="!$route.meta.hideFooter"></content-footer> -->
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
//import ContentFooter from './ContentFooter.vue';
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    DashboardNavbar,
    //ContentFooter,
    FadeTransition
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
      isLoggedin: false
    };
  },
  created() {
    let login = localStorage.getItem("isLoggedin");
    this.isLoggedin = login === "Y";
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    async logout() {
      await localStorage.setItem("isLoggedin", "N");
      await localStorage.setItem('uid', -1);
      this.$router.go();
    }
  }
};
</script>
<style lang="scss">
</style>
