import Vue from "vue";
import Router from "vue-router";
import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";
import { checkAuthorization } from "./apiList";
Vue.use(Router);

async function guard(to, from, next) {
  let loggedIn = await localStorage.getItem("isLoggedin");
  loggedIn = loggedIn === "Y";
  if (loggedIn) {
    next(); // allow to enter route
  } else {
    next("/login"); // go to '/login';
  }
}

async function loginGuard(to, from, next) {
  let loggedIn = await localStorage.getItem("isLoggedin");
  loggedIn = loggedIn === "Y";
  if (!loggedIn) {
    next(); // allow to enter route
  } else {
    next("/dashboard"); // go to '/login';
  }
}

const router = new Router({
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/",
      redirect: "login",
      beforeEnter: loginGuard,
      component: AuthLayout,
      children: [
        {
          path: "/login",
          name: "login",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/Login.vue")
        },
        {
          path: "/error",
          name: "Error",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/Error/Error.vue")
        },
      ]
    },
    {
      path: "/",
      redirect: "dashboard",
      beforeEnter: guard,
      component: DashboardLayout,
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/Dashboard.vue")
        },
        {
          path: "/services",
          name: "Services",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/Services/Services.vue"
            )
        },
        {
          path: "/create-services",
          name: "Create Services",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/Services/CreateServices.vue"
            )
        },
        {
          path: "/edit-services/:id",
          name: "Edit Services",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/Services/CreateServices.vue"
            )
        },
        {
          path: "/api-endpoints",
          name: "Api Endpoints",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/ApiEndpoints/ApiEndpoints.vue"
            )
        },
        {
          path: "/create-api-endpoints",
          name: "Create Api Endpoint",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/ApiEndpoints/CreateApiEndpoints.vue"
            )
        },
        {
          path: "/edit-api-endpoints/:id",
          name: "Edit Api Endpoint",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/ApiEndpoints/CreateApiEndpoints.vue"
            )
        },
        {
          path: "/frontend-routes",
          name: "Frontend Routes",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/FrontendRoutes/FrontendRoutes.vue"
            )
        },
        {
          path: "/create-frontend-routes",
          name: "Create Frontend Routes",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/FrontendRoutes/CreateFrontendRoutes.vue"
            )
        },
        {
          path: "/create-frontend-routes",
          name: "Create Frontend Routes",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/FrontendRoutes/CreateFrontendRoutes.vue"
            )
        },
        {
          path: "/save-user-data",
          name: "save users",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/FrontendRoutes/AddUsers.vue"
            )
        },
        {
          path: "/edit-frontend-routes/:id",
          name: "Edit Frontend Routes",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/FrontendRoutes/CreateFrontendRoutes.vue"
            )
        },
        {
          path: "/roles",
          name: "Roles",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/Roles/Roles.vue")
        },
        {
          path: "/create-roles",
          name: "Create Role",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/Roles/CreateRoles.vue"
            )
        },
        {
          path: "/edit-roles/:id",
          name: "Edit Roles",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/Roles/CreateRoles.vue"
            )
        },
        {
          path: "/users",
          name: "Users",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/Users/Users.vue")
        },
        {
          path: "/admin-users",
          name: "Admin User",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/AdminUsers/AdminUser.vue")
        },
        {
          path: "/create-users",
          name: "Create Users",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/Users/CreateUsers.vue"
            )
        },
        {
          path: "/edit-users/:id",
          name: "Edit Users",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/Users/UpdateUser.vue"
            )
        },
        {
          path: "/next/:userid",
          name: "records",
          component: () =>
            import(
              /* webpackChunkName: "demo" */ "./views/Users/ApiHitsRecord.vue"
            )
        },
        {
          path: "/error",
          name: "Error",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/Error/Error.vue")
        },
      ]
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  let toPath = to;
  if(toPath=="/error"){
    console.log('error');
  }
  else{
  const paramKeys = Object.keys(to.params);
  if (paramKeys && paramKeys.length > 0) {
    paramKeys.forEach((key) => {
      toPath = toPath.path.split("/");
      toPath = toPath.map((path) => {
        if (path == to.params[key]) {
          return ":" + key;
        }
        return path;
      });
    });
    toPath = toPath.join("/");
  } else {
    toPath = toPath.path;
  }}
  next();
  try {
    const res =  await checkAuthorization({ path: toPath, user_id: 111 });
    if (!res || !res.success) {
      next("error");
    }
  } catch (error) {
    next("error");
  }  
});

export default router;
