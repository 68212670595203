import { api } from "./api";
const AUTH_SERVICE_URL = process.env.VUE_APP_AUTH_SERVICE_URL;
const TRACKING_SERVICE_URL = process.env.VUE_APP_TRACKING_SERVICE_URL;
const MAIN_NP_URL = process.env.VUE_APP_MAIN_NP_URL;
//add user in admin
export const addUser = async (body = {}) => {
  let url = AUTH_SERVICE_URL + "admin-users/add-user";
  let method = "POST";
  let res = await api(url, method, body);
  return res;
};
// add user
export const createUser = async (body = {}) => {
  let url = AUTH_SERVICE_URL + "users/add-user";
  let method = "POST";
  let res = await api(url, method, body);
  return res;
};

// get user
export const getUserRecord = async () => {
  let url = AUTH_SERVICE_URL + "/admin-users/get-user";
  let method = "GET";
  let res = await api(url, method, {});
  return res; 
};



////records
export const getapicall = async (userid , page , limit) => {
  let url = TRACKING_SERVICE_URL + "api-tracking/get-api-call/" + userid +'?page='+ page +'&limit='+ limit;
  let method = "GET";
  let res = await api(url, method, {});
  return res;
};
//signin user
export const signinUser = async (body = {}) => {
  let url = AUTH_SERVICE_URL + "admin-users/sign-in";
  let method = "POST";
  let res = await api(url, method, body);
  return res;
};

//Check Auth
export const checkAuthorization = async (body = {}) => {
  let url = AUTH_SERVICE_URL + "auth/authenticate-frontend-route";
  let method = "POST";
  let res = await api(url, method, body);
  return res;
};
   
//API Endpoints
export const getApiEndpoints = async (page = 1 , search) => {
  let url = AUTH_SERVICE_URL + "api-endpoints/get-endpoints?page=" + page + '&search=' + search;
  let method = "GET";
  let res = await api(url, method, {});
  return res;
};

export const getAllApiEndpoints = async (body = {}) => {
  let url = AUTH_SERVICE_URL + "api-endpoints/get-all-endpoints";
  let method = "GET";
  let res = await api(url, method, body);
  return res;
};

export const getApiEndpoint = async (id, body = {}) => {
  let url = AUTH_SERVICE_URL + "api-endpoints/get-endpoint/" + id;
  let method = "GET";
  let res = await api(url, method, body);
  return res;
};

export const addApiEndpoint = async (body = {}) => {
  let url = AUTH_SERVICE_URL + "api-endpoints/create-endpoint";
  let method = "POST";
  let res = await api(url, method, body);
  return res;
};

export const editApiEndpoint = async (id, body = {}) => {
  let url = AUTH_SERVICE_URL + "api-endpoints/update-endpoint/" + id;
  let method = "PUT";
  let res = await api(url, method, body);
  return res;
};

export const deleteApiEndpoint = async (id, body = {}) => {
  let url = AUTH_SERVICE_URL + "api-endpoints/delete-endpoint/" + id;
  let method = "DELETE";
  let res = await api(url, method, body);
  return res;
};

//Frontend Routes
export const getFrontendRoutes = async (page = 1 , search) => {
  let url = AUTH_SERVICE_URL + "frontend-routes/get-frontend-routes?page=" + page + '&search=' + search;
  let method = "GET";
  let res = await api(url, method, {});
  return res;
};

export const getAllFrontendRoute = async (body = {}) => {
  let url = AUTH_SERVICE_URL + "frontend-routes/get-all-frontend-routes";
  let method = "GET";
  let res = await api(url, method, body);
  return res;
};

export const getFrontendRoute = async (id, body = {}) => {
  let url = AUTH_SERVICE_URL + "frontend-routes/get-frontend-route/" + id;
  let method = "GET";
  let res = await api(url, method, body);
  return res;
};

export const addFrontendRoute = async (body = {}) => {
  let url = AUTH_SERVICE_URL + "frontend-routes/create-frontend-route";
  let method = "POST";
  let res = await api(url, method, body);
  return res;
};

export const editFrontendRoute = async (id, body = {}) => {
  let url = AUTH_SERVICE_URL + "frontend-routes/update-frontend-route/" + id;
  let method = "PUT";
  let res = await api(url, method, body);
  return res;
};

export const deleteFrontendRoute = async (id, body = {}) => {
  let url = AUTH_SERVICE_URL + "frontend-routes/delete-frontend-route/" + id;
  let method = "DELETE";
  let res = await api(url, method, body);
  return res;
};

//Roles
export const getRoles = async (search, body = {}) => {
  let url = AUTH_SERVICE_URL + "roles/get-roles?search=" + search;
  let method = "GET";
  let res = await api(url, method, body);
  return res;
};

export const getRole = async (id, body = {}) => {
  let url = AUTH_SERVICE_URL + "roles/get-role/" + id;
  let method = "GET";
  let res = await api(url, method, body);
  return res;
};

export const addRole = async (body = {}) => {
  let url = AUTH_SERVICE_URL + "roles/create-role";
  let method = "POST";
  let res = await api(url, method, body);
  return res;
};

export const editRole = async (id, body = {}) => {
  let url = AUTH_SERVICE_URL + "roles/update-role/" + id;
  let method = "PUT";
  let res = await api(url, method, body);
  return res;
};

export const deleteRole = async (id, body = {}) => {
  let url = AUTH_SERVICE_URL + "roles/delete-role/" + id;
  let method = "DELETE";
  let res = await api(url, method, body);
  return res;
};

//Users
export const getUsers = async (page = 1,search) => {
  let url = AUTH_SERVICE_URL + "users/get-users?page=" + page + '&search=' + search;
  let method = "GET";
  let res = await api(url, method, {});
  return res;
};

export const getUser = async (id, body = {}) => {
  let url = AUTH_SERVICE_URL + "users/get-user/" + id;
  let method = "GET";
  let res = await api(url, method, body);
  return res;
};

export const editUserRoles = async (id, body = {}) => {
  let url = AUTH_SERVICE_URL + "users/update-role/" + id;
  let method = "PUT";
  let res = await api(url, method, body);
  return res;
};

export const importUsersCsv = async (body = {}) => {
  let url = AUTH_SERVICE_URL + "users/import-from-csv";
  let method = "POST";
  let res = await api(url, method, body);
  return res;
};

export const importRoutesJson = async (body = {}) => {
  let url = AUTH_SERVICE_URL + "frontend-routes/import-from-json";
  let method = "POST";
  let res = await api(url, method, body);
  return res;
};
//Services
export const getServices = async (page = 1) => {
  let url = AUTH_SERVICE_URL + "services/get-services?page=" + page;
  let method = "GET";
  let res = await api(url, method, {});
  return res;
};

export const getAllServices = async (body = {}) => {
  let url = AUTH_SERVICE_URL + "services/get-all-services";
  let method = "GET";
  let res = await api(url, method, body);
  return res;
};

export const getService = async (id, body = {}) => {
  let url = AUTH_SERVICE_URL + "services/get-service/" + id;
  let method = "GET";
  let res = await api(url, method, body);
  return res;
};

export const importEndpointsJson = async (body = {}) => {
  let url = AUTH_SERVICE_URL + "api-endpoints/import-from-json";
  let method = "POST";
  let res = await api(url, method, body);
  return res;
}
export const addService = async (body = {}) => {
  let url = AUTH_SERVICE_URL + "services/create-service";
  let method = "POST";
  let res = await api(url, method, body);
  return res;
};

export const editService = async (id, body = {}) => {
  let url = AUTH_SERVICE_URL + "services/update-service/" + id;
  let method = "PUT";
  let res = await api(url, method, body);
  return res;
};

export const deleteService = async (id, body = {}) => {
  let url = AUTH_SERVICE_URL + "services/delete-service/" + id;
  let method = "DELETE";
  let res = await api(url, method, body);
  return res;
};

export const getTags = async (body = {}) => {
  let url = MAIN_NP_URL + "coursetag/get";
  let method = "GET";
  let res = await api(url, method, body);
  return res;
};

export const getCourses = async (body = {}) => {
  let url = MAIN_NP_URL + "courses/search-courses-list";
  let method = "GET";
  let res = await api(url, method, body);
  return res;
};