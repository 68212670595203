import axios from "axios";

export const api = async (url, method, body) => {
  let request = {};
  const uid = await localStorage.getItem("uid");
  if (method === "POST" || method === "PUT") {
    let headers = { "Content-Type": "application/json", uid: uid };
    request = { method, url, headers, data: body };
  } else {
    let headers = { uid: uid };
    request = { method, url, body, headers };
  }
  return axios(request)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
